.control-title {
  padding: 10px;
}
.control-title-text {
  padding: 10px;
  background: #000000;
  color: #fff;
  border-radius: 20px;
  height: 40px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 800;
  letter-spacing: 1.5px;
  text-align: center;
}
.control-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  user-select: none;
  align-items: flex-start;
}
/* .control-container {
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: center;
  user-select: none;
} */

.control-container {
  margin: 10px;
  width: 60%;
  max-width: 300px;
  min-width: 250px;
  background-color: #080e36bb;
  border-radius: 20px;
  text-align: center;
  overflow: hidden;
}

.control-label {
  font-weight: 800;
  font-size: 18pt;
  color: #fff;
}

.control-buttons-container {
  display: flex;
  padding: 20px;
  justify-content: space-around;
}

.control-button {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff66;
  font-weight: 800;
  letter-spacing: 2px;
  cursor: pointer;
  transition: all 0.25s;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border: 1.5px solid #eee;
  overflow: hidden;
  font-size: 20px;
}
div.control-button:hover:not(.control-button-active) {
  background-color: #ffffff99;
  border: 5px solid #ffffffaa;
}
div.control-button:active {
  transform: scale(0.95);
  /* transition: all 0.2s; */
}
.control-button-off {
  color: #000;
}
.control-button-on {
  color: #164100;
}

.control-button-active {
  background-color: #ffffffee;
  transform: scale(1.05);
}

.control-devices-toggler {
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  padding: 7px;
  margin-top: 5px;
  background-color: #ffffff33;
  height: 35px;
}

.control-devices-container {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.control-devices-device {
  padding: 5px;
  margin: 5px;
  background-color: #000000a9;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 90%;
}
.control-devices-device-state {
  height: 6px;
  width: 6px;
  padding: 3px;
  border-radius: 50%;
  margin: 5px;
}
.control-devices-device-state.on {
  background-color: green;
}
.control-devices-device-state.off {
  background-color: red;
}
