div.display-outer {
  background-color: black;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
div.display-inner {
  height: inherit;
  width: inherit;
  background-color: #000;
  position: relative;
}

div.display-element-outer {
  background-color: transparent;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  position: absolute;
  left: 0px;
  top: 0px;
  transition: opacity 0.5s linear;
  /* transition: none; */
}
.notLive {
  opacity: 0 !important;
  /* box-shadow: none; */
  /* visibility: hidden;
  transition: visibility 0.5s linear; */
}
div.display-element-outer.live {
  transition: opacity 0.5s linear, visibility 0.5s linear;
  /* transition: none; */
  opacity: 1;
  /* visibility: visible; */
}

div.display-element-outer.notReady > div {
  /* transition: none;
  opacity: 0 !important; */
}
div.display-element-inner {
  height: 100%;
  width: 100%;
  background-color: transparent;
  position: relative;
  transition: none;
  overflow: hidden;
}
div.display-element-inner iframe {
  /* transform: translateY(0px); */
}
div.textElement-title {
  padding: 20px 40px;
  font-size: 100px;
  color: white;
  text-align: center;
}
div.textElement-heading {
  padding: 20px 40px;
  font-size: 50px;
  color: white;
  text-align: center;
}
div.textElement-subheading {
  padding: 20px 40px;
  font-size: 30px;
  color: white;
  text-align: center;
}
div.textElement-text {
  padding: 20px 40px;
  font-size: 20px;
  color: white;
  text-align: center;
}
div.textElement-translation {
  padding: 20px 40px;
  font-size: 5vw;
  overflow-y: scroll;
  color: white;
  text-align: start;
  white-space: pre-line;
}
